body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1, .h1, h2, .h2, h3, .h3 {
	margin-top: 22px;
	margin-bottom: 11px;
}

h4, .h4, h5, .h5, h6, .h6 {
	margin-top: 11px;
	margin-bottom: 11px;
}

.lead p {
	@media (min-width: 768px)
	{
	    font-size: 24px;
	}
}


