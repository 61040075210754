#projects {
    .row {
        & > div:nth-child(odd) {
            .project__thumbnail {
                background-color: $brand-primary;
            }
        }

        & > div:nth-child(even) {
            .project__thumbnail {
                background-color: $brand-secondary;
            }
        }
    }
    
    @media (min-width: $screen-sm-min) {
        .row.odd {
            & > div:nth-child(odd) {
                .project__thumbnail {
                    background-color: $brand-primary;
                }
            }

            & > div:nth-child(even) {
                .project__thumbnail {
                    background-color: $brand-secondary;
                }
            }
        }
        
        .row.even {
            & > div:nth-child(odd) {
                .project__thumbnail {
                    background-color: $brand-secondary;
                }
            }

            & > div:nth-child(even) {
                .project__thumbnail {
                    background-color: $brand-primary;
                }
            }
        }
    }
}

.project {

    &__thumbnail {
        position: relative;
        padding: 25px 25px 50%;
        overflow: hidden;
        background-color: $brand-primary;

        img {
            position: absolute;
            top: 20%;
            transition: all 0.3s ease-in-out;
            max-width: calc(100% - 50px);
        }

        &:hover {
            img {
                top: 10%;
            }
        }

    }

}

.project-images {

    &__item {
        padding: 40px;
        margin-bottom: 1em;

        &:nth-child(odd) {
            background-color: $brand-primary;
        }

        &:nth-child(even) {
            background-color: $brand-secondary;
        }
    }

}
