.images {
	padding: 30px;

	img.img-responsive {
		margin-bottom: 30px;
	}

	@media (min-width: 992px)
	{
		padding: 60px;

		img.img-responsive {
			margin-bottom: 60px;
		}
	}	
}

.text-center {
	img.img-responsive {
		margin: 0 auto;
	}
}

.img-responsive {
	width: 100% \9;
}

article {
	img {
		width: 100% \9;
		@include img-responsive();
	}
}

.no-svg img.img-responsive.logo-mark {
	width: 200px !important;
} 