#hero, #blog-header {

	h1 {
		color: #FFF;
		margin-top: 45px;
	}

	h2 {
		color: #BCBCBC;
		margin-bottom: 0;
	}
}