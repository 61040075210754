.tomorrow-comment, pre .comment, pre .title {
  color: #969896;
}

.tomorrow-red, pre .variable, pre .attribute, pre .tag, pre .regexp, pre .ruby .constant, pre .xml .tag .title, pre .xml .pi, pre .xml .doctype, pre .html .doctype, pre .css .id, pre .css .class, pre .css .pseudo {
  color: #cc6666;
}

.tomorrow-orange, pre .number, pre .preprocessor, pre .built_in, pre .literal, pre .params, pre .constant {
  color: #de935f;
}

.tomorrow-yellow, pre .class, pre .ruby .class .title, pre .css .rules .attribute {
  color: #f0c674;
}

.tomorrow-green, pre .string, pre .value, pre .inheritance, pre .header, pre .ruby .symbol, pre .xml .cdata {
  color: #b5bd68;
}

.tomorrow-aqua, pre .css .hexcolor {
  color: #8abeb7;
}

.tomorrow-blue, pre .function, pre .python .decorator, pre .python .title, pre .ruby .function .title, pre .ruby .title .keyword, pre .perl .sub, pre .javascript .title, pre .coffeescript .title {
  color: #81a2be;
}

.tomorrow-purple, pre .keyword, pre .javascript .function {
  color: #b294bb;
}

pre code {
  display: block;
  background: #1d1f21;
  color: #c5c8c6;
  padding: 0.5em;
}