section {
	padding: 80px 0;
	position: relative;
	background: #FFF;

	.section-header {
		padding: 40px 0;
		margin-bottom: 40px;
		text-align: center;

		&:before, &:after {
			content: '';
			display: block;
			height: 100px;
			background: url('../images/mark.svg') center no-repeat;
			background-size: 100px;

			.no-svg & {
				background-image: url('../images/mark.png');
			}
		}

		$mark-offset: 60px;

		&:before {
			margin-left: $mark-offset;
		}

		&:after {
			margin-left: -$mark-offset;
		}

		h1,h2,h3,h4,h5,h6 {
			color: #000;
			font-size: 50px;
			letter-spacing: 4px;
			text-transform: uppercase;
			margin: 10px 0;
		}

		&.blue:before, &.blue:after {
			background-image: url('../images/mark-blue.svg');

			.no-svg & {
				background-image: url('../images/mark-blue.png');
			}
		}

		@media (max-width: 768px)
		{
			padding: 20px 0;
			
			h1,h2,h3,h4,h5,h6 {
				font-size: 32px;
			}

			&:before, &:after {
				height: 30px;
				background-size: 30px;
			}

			$mark-offset: 25px;

			&:before {
				margin-left: $mark-offset;
			}

			&:after {
				margin-left: -$mark-offset;
			}
		}
	}
}

.full-window {
	width: 100vw;
	height: 100vh;
	min-height: 500px;
}