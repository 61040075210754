div.meta {
	p {
		font-size: 12px;
		color: #7D7D7D;
	}
}

#post-header {
	background-color: $brand-primary;

	h1 {
		color: #FFF;
	}

	&, a {
		color: #BCBCBC;
	}
}

#post-content {
	padding-bottom: 50px;

	pre {
		color: #EAEAEA;
		background: #1D1E20;
		padding: 20px 40px;
		border-radius: 0;
		border: none;
		line-height: 1.3em;
		font-size: 14px;

		& + pre {
			margin-top: - $line-height-computed / 2;
		}
	}
}

.post-content {
	padding-bottom: 40px;

	h3, .h3,
	h4, .h4,
	h5, .h6 {
		&:after {
			display: block;
			content: "";
			width: 60px;
			height: 1px;
			border-bottom: 3px solid $brand-primary;
			margin: 10px 0 20px;
		}
	}
}

.post-nav {
	margin: 40px 0;
}