section#contact {
	padding-bottom: 60px;
}

section#contact-form {
	background-color: $brand-primary;
	color: #FFF;
	
	.checkbox {
		margin: 30px 0;
	}
}
