body > header {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1030;

	&.navbar-blue nav.navbar-sdd {
		background-color: $brand-primary;
	}

	&.navbar-highlight {
		#navbar-toggle .icon-bar {
			background-color: $brand-primary;
		}

		&.in #navbar-toggle .icon-bar {
			background-color: #FFF;
		}
	}

	&.in {
		nav.navbar-sdd {
			transform: translateY(0);
		}

		#navbar-toggle {
			.icon-bar.middle {
				left: 50px;
			}

			.icon-bar.top {
				transform: rotate( 45deg );
				top: 8px;
			}

			.icon-bar.bottom {
				transform: rotate( -45deg );
				top: 8px;
				margin-top: 0;
			}
		}
	}
}

nav.navbar-sdd {
	background-color: $brand-secondary;
	min-height: 0;
	transform: translateY(-100%);

	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	transition: all 0.3s ease;

	.nav > li > a {
		font-family: 'Montserrat', sans-serif;
		text-transform: uppercase;
		color: #FFF;
		letter-spacing: 1px;
		padding: 15px;
	}

	.nav > li {
		&.active > a, & > a:hover, & > a:focus {
			background-color: transparent;
			color: #000;
			outline: none;
		}
	}
}

#navbar-toggle {
	display: block !important;
	position: absolute;
	top: 8px;
	right: 8px;
	z-index: 1031;

	.icon-bar {
		background-color: #FFF;
		position: absolute;
		left: 0;

		&.top {
			top: 0;
		}

		&.middle {
			top: 2px;
		}

		&.bottom {
			top: 8px;
		}

		-webkit-transition: all 0.6s ease;
		-moz-transition: all 0.6s ease;
		-o-transition: all 0.6s ease;
		-ms-transition: all 0.6s ease;
		transition: all 0.6s ease;
	}

	&:hover {
		.icon-bar {
			background-color: #000;
		}
	}
}