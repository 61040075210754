.center-vertically-wrapper {
	position: relative;
	display: table;
	width: 100%;
	height: 100%;

	.center-vertically {
		display: table-cell;
		vertical-align: middle;
	}
}