.footer {
	text-align: center;
	font-size: 12px;

	p {
		margin-bottom: 1em;
	}

	.menu {
		ul {
			@extend .list-inline;
		}
	}
}

img#wpstats{width:0px;height:0px;overflow:hidden}