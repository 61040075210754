@mixin row-first-child($col-type) {
	.col-#{$col-type}- {
		&1:nth-child(12n+1),
		&2:nth-child(6n+1),
		&3:nth-child(4n+1),
		&4:nth-child(3n+1),
		&6:nth-child(odd){
			clear: left;
		}
	}
} 

.auto-clear {
	@media (min-width: $screen-lg-min){
		@include row-first-child(lg);
	}
	@media (min-width: $screen-md-min) and (max-width: $screen-md-max){
		@include row-first-child(md);
	}
	@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max){
		@include row-first-child(sm);
	}
	@media (max-width: $screen-xs-max){
		@include row-first-child(xs);
	}
}