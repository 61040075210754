.bg-primary {
    color: $brand-primary;
}

.bg-secondary {
    background-color: $brand-secondary;

    &, a {
        color: #FFF;
    }

    a {
        text-decoration: underline;

        &:hover, &:focus, &:active {
            text-decoration: none;
        }
    }
}
