.social {
	margin: 20px 0 30px;

	ul {
		display: inline-block;
		margin: 0;
		padding: 0;

		li {
			$social-size: 60px;
			display: inline-block;
			width: $social-size;
			height: $social-size;
			line-height: $social-size;
			border-radius: $social-size / 2;
			text-align: center;
			font-size: $social-size / 2;
			background-color: #ababab;
			margin: 0 6px;

			a {
				color: #FFF;

				&:hover {
					color: #000;
				}
			}

			&.twitter {
				background-color: #55acee;
			}

			&.linkedin {
				background-color: #0077B5;
			}
		}
	}
}