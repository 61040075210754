$brand-primary: #2a6ab1;
$brand-secondary: #EA5D5A;

$body-bg: $brand-secondary;

$panel-border-radius: 0;

$btn-border-radius-base: 0;
$btn-border-radius-large: 0;

$font-family-base: 'Roboto', sans-serif;
$headings-font-family: 'Montserrat', sans-serif;
$headings-font-weight: 600;
$font-size-base: 16px;
$line-height-base: 1.4;
$line-height-computed: 40px; // Double as its halved in Bootstrap

$input-height-base: 40px;
$input-border: transparent;
